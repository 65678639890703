<template>
	<div class="card p-grid p-fluid">
		<iframe ref='xml_visualization' id='note_container' src="Xml2Tree/index.html" frameborder="0"  width="100%"></iframe>
	</div>
</template>

<script>
	export default {
		name: '',
		props: {
			para_copy: {
				default: null,
				type: Object
			}
		},
		components: {

		},
		data() {
			return{
				para:this.para_copy, //此后para可在methods中操作
			}
		},
		networkService:null,
		created() {
			
		},
		mounted() {
			console.log('组件加载成功');
		},
		methods: {
			
		},
		}
</script>

<style scoped lang="scss">
</style>
